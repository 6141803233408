import cn from "class-names"
import styles from "@css/modules/Listings.module.scss"
import React from "react"
import { Listing } from "@t/Listing"

type KnowTypes = "New" | "Available" | "Price Reduced" | "Double Points" | "Stripped" | "Sold"

const statusMap: Record<KnowTypes, { color?: string; text?: string }> = {
    New: {
        text: "New",
        color: "#FF52E3"
    },
    Available: {
        text: "Available",
        color: "#38FFAB"
    },
    "Double Points": {
        text: "Double Pts",
        color: "#FFE4A0"
    },
    "Price Reduced": {
        text: "Price Reduced",
        color: "#b1ff52"
    },
    Stripped: {
        text: "Stripped",
        color: "#cd6423"
    },
    Sold: {
        text: "Sold",
        color: "#e01515"
    }
}
type Props = {
    className?: string
    listing: Listing
}

export function ListingStatus({ className, listing }: Props) {
    const statuses = listing.listing_status.split(";")
    return statuses.map((i, key) => {
        const { color, text } = statusMap[i] || { text: i, color: "#fff" }
        return (
            <span key={key} className={cn(styles.statusBtn, className)} title={i} style={{ "--statusColor": color }}>
                {text}
            </span>
        )
    })
}
