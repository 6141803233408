import { resortPopulate, resortSeoPopulate } from "./resorts"
import { expandWhere, ExpandWhereProps } from "@utils/search"
import { ownNotActiveStatuses } from "@utils/const"
import { getPointMinMax } from "@utils/listingUrl"
import { fetchRest, StrapiResponse } from "@lib/fetchRest"
import { Listing, ListingAdResponse, ListingAdUi } from "@t/Listing"
import moment from "moment"

type GetAllListingsProps = {
    pageSize?: number
    sort?: string
    page?: number
    where?: ExpandWhereProps
}

export async function getAllListings(props: GetAllListingsProps) {
    let { pageSize = 20, sort = "createdAt:desc", page = 1 } = props
    const filters = expandWhere(props.where, true)
    if (!sort.includes("stars")) {
        sort += ",stars:desc"
    } else {
        sort += ",createdAt:desc"
    }
    if (!sort.includes("id:desc")) {
        sort += ",id:desc"
    }
    const { data, meta } = await fetchRest("listings", {
        filters: filters,
        sort: sort.split(","),
        fields:
            JSON.stringify(filters).length > 1000
                ? undefined
                : [
                      "listing_id",
                      "parsed_date",
                      "createdAt",
                      "link",
                      "listing_status",
                      "broker_status",
                      "stars",
                      "origin",
                      "price",
                      "origin",
                      "price_per_point",
                      "points_on_contract",
                      "current_year_points",
                      "dues_reimbursement",
                      "deed_exp",
                      "closing_cost",
                      "use_month",
                      "parsed_date",
                      "points"
                  ],
        pagination: {
            withCount: true,
            pageSize,
            page
        },
        populate: {
            resort: {
                fields: ["short_name", "id"]
            }
        }
    })
    return { listings: { data, meta } }
    /* return await fetchAPI(
        `
query getAllListings(
  $published: PublicationState
  $sort: [String]
  $page: Int
  $pageSize: Int
  $where: ListingFiltersInput
) {
  listings(
    publicationState: $published
    sort: $sort
    pagination: {page: $page, pageSize: $pageSize}
    filters: $where
  ) {
    meta {
      pagination {
        total
        pageCount
        page
        pageSize
      }
    }
    data {
      id
      attributes {
        listing_id
        parsed_date
        createdAt
        link
        status
        stars
        origin
        price
        origin
        price_per_point
        points_on_contract
        current_year_points
        dues_reimbursement
        deed_exp
        closing_cost
        use_month
        parsed_date
        points
        resort {
          data {
            id
            attributes {
              short_name
            }
          }
        }
      }
    }
  }
}`,
        {
            variables: {
                preview: config.preview ? "PREVIEW" : "LIVE",
                pageSize,
                sort,
                page,
                where
            }
        }
    )*/
}

export async function getListingsByIds([_, ids]) {
    if (!ids?.length) return { listings: [] }
    const { data: listings } = await fetchRest<Listing[]>("listings", {
        filters: {
            id: {
                $in: ids
            }
        },
        sort: "id:asc",
        pagination: {
            limit: 100
        },
        populate: {
            resort: {
                fields: ["id", "short_name"]
            }
        },
        fields: [
            "id",
            "listing_id",
            "link",
            "listing_status",
            "broker_status",
            "stars",
            "origin",
            "price",
            "price_per_point",
            "points_on_contract",
            "current_year_points",
            "dues_reimbursement",
            "closing_cost",
            "use_month",
            "parsed_date",
            "points"
        ]
    })
    return { listings }
}

export async function getListingById(id: string) {
    const { data } = await fetchRest<Listing>("listings/" + id, {
        populate: {
            price_updates: {
                fields: ["price", "date"]
            },
            resort: {
                populate: {
                    ...resortPopulate,
                    partner: {
                        populate: {
                            ...resortPopulate
                        }
                    }
                }
            }
        }
    })
    return data
}

export async function getListingSeoById(id: string) {
    const { data } = await fetchRest<Listing>(
        "listings/" + id,
        {
            populate: {
                resort: {
                    fields: ["short_name", "id", "slug"],
                    populate: {
                        ...resortSeoPopulate
                    }
                }
            }
        },
        undefined,
        3600
    )
    return data
}

export async function getListingsFilters() {
    const req1 = fetchRest("listing-filter")
    const req2 = fetchRest("resorts", {
        filters: {
            $and: [{ $or: [{ nonDisneyWorld: { $eq: false } }, { nonDisneyWorld: { $null: true } }] }, { $or: [{ is_visible: { $eq: true } }, { is_visible: { $null: true } }] }]
        },
        fields: ["short_name", "id"],
        sort: "short_name:asc",
        pagination: {
            limit: 25
        }
    })
    const req3 = fetchRest("resorts", {
        pagination: { limit: 25 },
        filters: {
            nonDisneyWorld: { $eq: true, $notNull: true },
            $or: [{ is_visible: { $eq: true } }, { is_visible: { $null: true } }]
        },
        fields: ["short_name", "id"],
        sort: "short_name:asc"
    })
    const [{ data: listingFilter }, { data: disneyResorts }, { data: nonDisneyResorts }] = await Promise.all([req1, req2, req3])
    //todo add types
    return {
        listingFilter: listingFilter as any,
        disneyResorts: disneyResorts as any,
        nonDisneyResorts: nonDisneyResorts as any
    }
}

type DealScoreListing = Pick<Listing, "id" | "rank" | "stars" | "use_month" | "points_on_contract" | "price_per_point">

export async function getDealScoreData(listing: Pick<Listing, "id" | "resort" | "points_on_contract">): Promise<StrapiResponse<DealScoreListing[]>> {
    const { pointsMin, pointsMax } = getPointMinMax(listing)
    const result = await fetchRest(
        "listings",
        {
            filters: {
                resort: {
                    id: {
                        $eq: listing?.resort?.id
                    }
                },
                points_on_contract: {
                    $gte: pointsMin,
                    $lte: pointsMax
                },
                ["$and"]: ownNotActiveStatuses.map((i) => ({
                    listing_status: {
                        $notContains: i
                    }
                }))
            },
            sort: "rank:asc",
            pagination: {
                limit: 5,
                withCount: true
            },
            fields: ["id", "rank", "stars", "use_month", "points_on_contract", "price_per_point"]
        },
        undefined,
        3600 * 24 // one day cache
    )
    return {
        data: Array.isArray(result?.data) ? result?.data : [],
        meta: result?.meta || { pagination: { total: 0, limit: 0, start: 0 } }
    }
}

export async function getDealScoreOfSold(listing: Pick<Listing, "id" | "resort" | "points_on_contract">) {
    const { pointsMin, pointsMax } = getPointMinMax(listing)
    const result = await fetchRest<{ id: number }[]>(
        "listings",
        {
            filters: {
                resort: {
                    id: {
                        $eq: listing?.resort?.id
                    }
                },
                points_on_contract: {
                    $gte: pointsMin,
                    $lte: pointsMax
                },
                ["$and"]: ownNotActiveStatuses.map((i) => ({
                    listing_status: {
                        $notContains: i
                    }
                })),
                updatedAt: {
                    $gte: moment().add(-2, "months").valueOf() // 2 months
                }
            },
            sort: "rank:asc",
            pagination: {
                limit: 1,
                withCount: true
            },
            fields: ["id"]
        },
        undefined,
        3600 * 24 // one day cache
    )
    return { data: Array.isArray(result?.data) ? result?.data : [], meta: result?.meta || { pagination: { total: 0, limit: 0, start: 0 } } }
}

export async function getListingAd(): Promise<ListingAdUi | null> {
    const result = await fetchRest<ListingAdResponse>(
        "listing-ad",
        {
            fields: ["link", "displayAtPosition"],
            populate: {
                desktopImage: true,
                tabletImage: true,
                mobileImage: true
            }
        },
        undefined,
        300 // 5 min cache
    )
    return result?.data
        ? {
              ...result.data,
              displayAtPosition: result.data.displayAtPosition?.split(",") || []
          }
        : null
}
